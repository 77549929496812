// Very basic css styling setup for the surveyjs. Had to remove the bootstrap styling because it was interfering with the global styles
// Also couldn't work out how the use the surveyjs theming, and really don't know anything about it.

.sv_container {
  font-size: 1.5rem;
}

.sv_container .sv_p_title {
  margin-bottom: 0;
  padding: 0;
  color: rgb(2, 58, 112);
  border-bottom: 1px solid rgb(210, 211, 211);
  font-size: 1.2em;
  padding-bottom: 16px;
}

.sv_q_title {
  margin-bottom: 16px;
}

.sv_q_text_root {
  border-radius: 8px;
  border: 1px solid #353636;
  padding: 15.5px 14px;
  font-family: 'DM Sans', sans-serif;
}

.sv_next_btn {
  color: #fff;
  box-shadow: none;
  background-color: #0c72ce;
  border-radius: 100px;
  padding: 11px 16px;
  border: none;
  font-size: 0.8em;
  min-width: 100px;
}

.sv_qstn.sv_qstn .sv-q-col-2 {
  width: auto;
}

.sv_q_radiogroup_label span span,
.sv_q_checkbox span span {
  font-size: 0.8em;
}

.sv_q_erbox {
  font-size: 0.6em;
  color: #b00020;
}
